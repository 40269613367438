<script setup lang='ts'>
import type { PropType } from 'vue';
import type { IHit } from '~/types';
import { usePricing } from '#imports';

const props = defineProps({
    item: {
        type: Object as PropType<IHit>,
        required: true
    }
})

const show = ref(false);

function mouseEnter() {
    show.value = true;
}

function mouseLeave() {
    show.value = false;
}

// second and first picture for hovering effect
const second_picture = computed(() => {
    return props.item.images?.[1] ?? props.item.images?.[0];
});
const first_picture = computed(() => {
    return props.item.images?.[0] ?? props.item?.thumbnail;
});

const { isLoggedIn } = storeToRefs(useCustomerStore())

function closeModal() {
    useNuxtApp().$utilities.modal.search.value = false
}
</script>

<template>
    <div
        class="space-y-4 shadow hover:shadow-lg dark:shadow-gray-900 max-w-full h-fit bg-primary-50/80 dark:bg-gray-900">
        <Transition>
            <NuxtLink @click="closeModal" @mouseenter="mouseEnter" @mouseleave="mouseLeave"
                :to="`/shop/jewels/${item.handle}`" class="cursor-pointer">

                <LazyNuxtImg v-show="!show" :src="first_picture || ''" format="webp"
                    placeholder="placeholder_bazari.png" provider="weserv" preset="prod_listing"
                    class="w-full aspect-square prod_img" />
                <LazyNuxtImg v-show="show" :src="second_picture || ''" format="webp"
                    placeholder="placeholder_bazari.png" provider="weserv" preset="prod_listing"
                    class="w-full aspect-square prod_img" />

            </NuxtLink>
        </Transition>

        <div class="flex items-center justify-between px-4 py-2">
            <div class="flex flex-col gap-2 justify-start">
                <NuxtLink @click="closeModal" :to="`/shop/jewels/${item.handle}`" class="uppercase font-medium">{{
                    item.title }}</NuxtLink>

                <span v-if="isLoggedIn" class="text-start">
                    {{ usePricing(item.price) }}
                </span>
            </div>
            <NuxtLink @click="closeModal" :to="`/shop/jewels/${item.handle}`">
                <CyUiIconsShoppingBag />
            </NuxtLink>
        </div>
    </div>
</template>